.upcoming-events {
  .upcoming-events-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 216px;
    width: 100%;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    color: $gray300;
  }
}

.upcoming-events-list {
  .upcoming-events__item {
    display: flex;
    gap: 24px;
    height: 76px;
    margin-bottom: 12px;
    position: relative;

    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        bottom: -24px;
        right: 0;
        height: 1px;
        width: calc(100% - 100px);
        background-color: $gray100;
      }
    }
  }

  .upcoming-item-calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 76px;
    height: 100%;
    background: $grayPurple;
    border-radius: 4px;

    .upcoming-item-calendar__date {
      font-weight: 600;
      font-size: 21px;
      line-height: 28px;
      color: $black;
      letter-spacing: -0.5px;
    }

    .upcoming-item-calendar__month {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $primary;
    }
  }

  .upcoming-item-info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex: 1 0 0;
  }

  .upcoming-item-event {
    display: flex;
    justify-content: space-between;
    gap: 32px;
    width: 100%;
    position: relative;
    padding-right: 32px;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 32px;
      width: 1px;
      background-color: $gray100;
    }
  }

  .upcoming-item-event-info {
    display: flex;
    flex-direction: column;

    .upcoming-item-event-info__name {
      font-weight: 600;
      font-size: 21px;
      line-height: 28px;
      letter-spacing: -0.5px;
      color: $black;
    }

    .upcoming-item-event-info__status {
      margin-top: 6px;
      letter-spacing: 1px;
      text-transform: uppercase;

      .badge {
        padding: 4px 8px;
      }
    }
  }

  .upcoming-item-event-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 6px;
    background-color: $primary100;
    cursor: pointer;

    .icon {
      color: $primary;
    }
  }
}

.modal__upcoming-events-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
