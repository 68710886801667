.ant-modal-root {
  .ant-modal.mobile {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: 0;

    &.mobile-modal__dropdown-filter-modal {
      height: 270px;
      align-self: flex-start;

      .ant-modal-body {
        padding: 0;
      }

      .ant-modal-close {
        display: none;
      }
      .ant-modal-content {
        background-color: $grayPurple;
      }
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-close {
      top: 28px;
      right: 24px;
    }

    .ant-modal-wrap {
      top: 0;
      bottom: 0;
      padding: 0;
    }

    .modal__title {
      background: $white;
      position: absolute;
      top: -24px;
      z-index: 4;
      padding-top: 24px;
      width: calc(100% - 32px);
      height: 76px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $gray100;
    }

    .ant-modal-body {
      max-height: 100%;
    }

    .ant-modal-content {
      border-radius: 0;
    }

    .ant-modal-close-x {
      color: $black;
    }

    &.modal-with-sticky-header {
      .ant-modal-sticky-header {
        padding: 20px 16px 12px 16px;
        z-index: 4;
        top: 0;
      }
      .ant-modal-body-content {
        padding: 0 16px;
      }
      .ant-modal-body-container {
        margin-top: 0;
        .tab-label {
          height: 32px;
          padding: 0 16px 8px 16px;
          &.active {
            font-weight: 600;
          }
        }
        .mobile-tab-bar__tab-items {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: $gray200;
          }
        }
      }
    }
  }

  .ant-modal-close {
    z-index: 10;
    top: 16px;
    right: 16px;
  }

  .ant-modal {
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 44px;
    padding: 0;

    &.modal-with-sticky-header {
      .ant-modal-sticky-header {
        position: sticky;
        z-index: 2;
        top: -24px;
        padding: 24px 0 20px 0;
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        display: flex;
        align-items: center;
        background-color: $white;
        > span:first-child {
          color: $primary;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        span:not(:first-child) {
          padding-left: 8px;
        }
      }
      .ant-modal-body-container {
        position: relative;
        margin-top: -24px;
        overflow: initial;
      }
    }
  }

  .ant-modal-wrap {
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .ant-modal-content {
    height: 100%;
    border-radius: 12px;
    box-shadow: 0 4px 48px rgba(10, 9, 18, 0.08);
  }

  .ant-modal-body {
    font-family: "Averta Std";
    max-height: calc(100vh - 32px);
    padding: 24px 20px;
    overflow: auto;
    height: 100%;
  }

  .ant-modal-mask {
    background-color: transparentize($black, 0.25);
  }

  .ant-modal-close {
    top: 12px;
    right: -32px;
  }

  .ant-modal-close-x {
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: $white;
  }

  .modal__buttons {
    .btn + .btn {
      margin-left: 12px;
    }
  }

  .modal__title {
    margin-bottom: 20px;
  }

  .modal__classic {
    width: max-content !important;
    min-width: 368px;
  }

  .modal__upcoming-events {
    width: 668px !important;
    max-height: 664px;
    height: auto;
  }

  .modal__profile-sidebar {
    width: 424px !important;
    max-height: 608px;
    height: 100%;

    &.mobile {
      overflow: hidden;
      max-height: 100%;
    }

    .ant-modal-body {
      padding: 0;
    }
  }

  .modal__description {
    margin-top: 4px;
  }
}

.modal__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;

  .btn + .btn {
    margin-left: 12px;
  }
}

.ant-drawer {
  font-family: Averta Std;
}
