.your-learning {
  width: 100%;

  &.your-learning-empty {
    .empty-articles-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      min-height: 350px;
      width: 100%;
    }
  }

  .your-learning-articles {
    margin-top: 30px;
    .article-item-learning {
      &:not(:first-child) {
        margin-top: 24px;
      }
    }

    .your-learning-button {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 48px;
    }
  }
}
