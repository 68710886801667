.btn {
  position: relative;
  display: inline-flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  outline: 0;
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  transition: background 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;

  &-badge {
    position: absolute;
    top: -9px;
    right: -22px;
  }

  &.primary {
    background-color: $primary;
    font-weight: 600;
    color: $white;

    &.active {
      background-color: $primary800;
    }

    &:hover {
      background-color: $primary800;
    }

    &.disabled {
      background-color: $gray100;
      color: $gray300;
      cursor: not-allowed;
    }

  }

  &.secondary {
    background-color: $primary100;
    font-weight: normal;
    color: $primary;

    &.active {
      background-color: $primary200;
    }

    &:hover {
      background-color: $primary200;
    }

    &.disabled {
      background-color: $gray100;
      color: $gray300;
      cursor: not-allowed;
    }
  }

  .outline-white,
  &.outline-purple {
    &.lg {
      line-height: 24px;
    }

    &.xs {
      line-height: 18px;
    }
  }

  &.outline-white {
    border: 1px solid $gray200;
    background-color: $white;
    color: $black;
    gap: 8px;
    &:hover {
      border-color: $primary400;
      box-shadow: 0 2px 16px rgba(10, 9, 18, 0.06);
      color: $primary;

      .icon {
        color: $primary;
      }
    }
  }

  &.outline-purple {
    border: 1px solid $primary700;
    background-color: $white;
    &:hover {
      box-shadow: 0 2px 16px rgba(70, 62, 174, 0.08);
    }
  }

  &.outline-purple,
  &.text {
    color: $primary;
    &:hover {
      color: $primary700;

      .icon {
        color: $primary400;
      }
    }
  }

  &.text {
    background-color: transparent;

    &.disabled {
      cursor: not-allowed;
      color: $gray300;
    }
  }

  &.xs {
    padding: 6px 16px 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    line-height: 20px;

    .btn__text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  &.md {
    padding: 10px 32px 10px 28px;
    min-height: 44px;

    .btn__text {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  &.lg {
    padding: 12px 40px 12px 36px;

    .btn__text {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  &.block {
    width: 100%;
  }

  .icon {
    transition: color 0.3s;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      z-index: 1;
      cursor: not-allowed;
    }
  }
}

//TODO: refactor maybe
.show-more-btn {
  background: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    color: $gray700;
  }
  .icon {
    width: 16px;
    height: 16px;
  }

  .show-more-btn__name {
    margin-left: 12px;
  }
}
