.dashboard {
  .dashboard-box {
    display: flex;
    flex-grow: 1;
    width: 100%;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    border-radius: 16px;
    border: 1px solid $gray100;
    background: $white;
    overflow: hidden;
  }

  .dashboard__container {
    display: flex;
    flex-direction: column;
    gap: 48px;
    overflow: hidden;
  }

  .dashboard__recommended {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .dashboard__section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 228px;

    &-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: -0.5px;
      color: $black;
    }

    &-see-all {
      display: flex;
      padding: 6px 0 6px 12px;
      align-items: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: $primary;
      cursor: pointer;
    }

    &-see-all_disable {
      color: $gray300;
      cursor: not-allowed;
    }
  }

  .dashboard__recommended-content {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 16px;

    .article-item__related {
      flex-shrink: 0;
    }
  }

  .dashboard__course-and-events {
    display: flex;
    gap: 16px;

    @media (max-width: 1440px) {
      flex-direction: column;
    }
  }

  .dashboard__courses {
    gap: 28px;
  }

  .dashboard__courses-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-self: stretch;

    .dashboard__courses-content-more {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 48px 0;
    }
  }

  .dashboard__events-content {
    display: flex;
    flex-direction: column;
    gap: 28px;
    align-self: stretch;
  }

  .dashboard__bookmarks {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .dashboard__bookmarks-content {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    overflow-x: auto;

    .article-item__related {
      flex-shrink: 0;
    }
  }

  .dashboard__courses-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 24px;
    max-width: 260px;
    padding: 62px 0;

    .empty-articles-title__img {
      width: 160px;
      height: 134px;
    }

    .dashboard__courses-empty-title {
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
      color: $black;
      text-align: center;
    }
  }

  .dashboard__events-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 84px 0 64px 0;

    .dashboard__events-empty-title {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      color: $gray300;
      max-width: 300px;
      text-align: center;
    }
  }

  &__block {
    position: relative;
    padding: 32px;
    border-radius: 16px;
    border: 1px solid $gray100;
    background-color: $white;
  }

  &-welcome {
    display: flex;
    align-items: center;
    width: 100%;

    &__icon {
      width: 36px;
      height: auto;
      margin-top: 6px;
      margin-bottom: auto;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 24px;

      .s-5 {
        margin-top: 8px;
        color: $gray700;
      }
    }

    .btn {
      min-width: 152px;
      margin-left: auto;
    }
  }
  &-onboarding {
    .dashboard-onboarding__inner {
      display: flex;
      align-items: flex-end;
      width: 100%;
      margin-top: 8px;

      .btn {
        margin-left: auto;
        white-space: nowrap;
      }

      .timeline-container {
        margin-right: 32px;
      }
    }
  }

}

.saved-page-empty {
  display: flex;
    flex-direction: column;
}
