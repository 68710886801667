.onboarding-page.mobile {
  .onboarding {
    padding: 24px 0;
  }

  .onboarding__inner {
    margin: auto 0;
  }

  .onboarding-steps {
    margin-top: 32px;
  }

  .onboarding-steps__item {
    justify-content: initial;
  }

  .onboarding-steps__item:first-child .onboarding-steps__circle {
    margin-left: 0;
  }

  .onboarding-steps__item:last-child .onboarding-steps__circle {
    margin-right: 0;
  }


  .onboarding-steps__circle {
    width: 36px;
    height: 36px;
    margin: 0 44px;
    font-size: 16px;
  }

  .onboarding__title {
    margin-top: 32px;
    font-size: 20px;
    line-height: 28px;
  }

  .onboarding__subtitle {
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
  }

  .onboarding-footer__filled {
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
  }

  .onboarding-footer__buttons {
    width: 100%;

    .btn {
      min-width: auto;

      + .btn {
        margin-left: 12px;
      }
    }
  }

  .onboarding-footer,
  .onboarding-tags,
  .onboarding-form {
    margin-top: 32px;
  }
}

.onboarding {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 48px 15px;

  @media (max-width: 1440px) {
    padding: 36px 15px;
  }

  &__inner {
    margin: auto;
  }

  &-steps {
    display: flex;
    justify-content: center;
    margin-top: 88px;

    @media (max-width: 1440px) {
      margin-top: 40px;
    }

    &__circle {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      margin: 0 50px;
      border: 2px solid $gray100;
      background-color: $white;
      border-radius: 50%;
      font-weight: 600;
      font-size: 18px;
      color: $black;
      transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    }

    &__item {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child:after {
        position: absolute;
        content: '';
        right: 0;
        width: 50px;
        height: 2px;
        margin: auto 0;
        background-color: $gray100;
      }

      &:last-child:after {
        position: absolute;
        z-index: 1;
        content: '';
        left: 0;
        width: 50px;
        height: 2px;
        margin: auto 0;
        background-color: $gray100;
      }

      &:after {
        position: absolute;
        content: '';
        width: 148px;
        height: 2px;
        margin: auto;
        background-color: $gray100;
        transition: background-color 0.3s;
      }

      &.active {
        .onboarding-steps__circle {
          border-color: $primary;
          background-color: $primary;
          color: $white;
        }

        &:after {
          background-color: $primary;
        }
      }
    }
  }

  &__title {
    margin-top: 64px;
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: $black;

    @media (max-width: 1440px) {
      margin-top: 48px;
    }
  }

  &__subtitle {
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: $gray600;
  }

  &-form {
    max-width: 496px;
    width: 100%;
    margin-top: 48px;

    @media (max-width: 1440px) {
      margin-top: 36px;
    }

    .field + .field {
      margin-top: 20px;
    }
  }

  &-tags {
    max-width: 752px;
    width: 100%;
    margin-top: 48px;

    &__list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .tag-button {
        margin: 6px;
      }
    }
  }

  &-descriptions {
    max-width: 432px;
    width: 100%;
    margin-top: 48px;

    &-item {
      display: flex;
      align-items: flex-start;

      + .onboarding-descriptions-item {
        margin-top: 36px;
      }

      @media (max-width: 1440px) {
        + .onboarding-descriptions-item {
          margin-top: 24px;
        }
      }

      &__title {
        font-weight: 600;
        font-size: 21px;
        line-height: 28px;
        letter-spacing: -0.5px;
        color: $black;

        @media (max-width: 1440px) {
          font-size: 18px;
          line-height: 26px;
        }
      }

      &__text {
        margin-top: 8px;
        font-size: 18px;
        line-height: 26px;
        color: $gray700;

        @media (max-width: 1440px) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      &__icon {
        margin-top: 10px;

        @media (max-width: 1440px) {
          width: 36px;
          height: auto;
        }
      }

      &__inner {
        margin-left: 24px;
      }
    }
  }

  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;

    @media (max-width: 1440px) {
      margin-top: 36px;
    }

    &__buttons {
      display: flex;

      .btn + .btn {
        margin-left: 16px;
      }
    }

    .btn {
      min-width: 196px;
    }

    &__filled {
      margin-top: 16px;
      font-size: 18px;
      line-height: 26px;
      color: $primary;

      &.disabled {
        color: $gray500;
      }
    }
  }
}
