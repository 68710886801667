%tag-label-xs {
  padding: 3px 8px;
  .tag-label-icon {
    width: 14px;
    height: 14px;
  }
}
%tag-label-sm {
  padding: 5px 12px;
  .tag-label-icon {
    width: 14px;
    height: 14px;
  }
}
%tag-label-md {
  padding: 5px 12px;
  font-size: 16px;
  line-height: 24px;
  .tag-label-icon {
    width: 16px;
    height: 16px;
  }
}
.tag-label {
  display: flex;
  align-items: center;
  border: 1px solid $gray100;
  background-color: $white;
  border-radius: 6px;
  color: $black;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.pointer-events {
    pointer-events: none;
  }

  //TODO: refactor without article, as ui kit
  &.article {
    padding: 6px 12px;
    background-color: $gray100;
    border-radius: 8px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    &:hover {
      background-color: $primary100;
    }
  }

  &:hover {
    border-color: $primary;
    color: $primary;

    .tag-label-icon,
    .icon {
      fill: $primary;
    }
  }

  &.profile-filter {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 16px;
    background-color: $gray100;
    color: $gray900;
    &.active {
      background-color: $primary200;
      color: $primary;
      border: 1px solid transparent;
    }
  }

  &.mandatory-article-label {
    background: $accent;
    border: 1px solid $accent600;
    color: $white;
  }

  .tag-label-icon {
    margin-right: 6px;
  }
  &.xs {
    @extend %tag-label-xs;
  }
  &.sm {
    @extend %tag-label-sm;
  }
  &.md {
    @extend %tag-label-md;
  }
  &.article-topics {
    padding: 6px 12px;
    gap: 8px;
    font-weight: 400;
    background: $grayPurple;
    border-radius: 8px;
    border-color: transparent;
    color: $gray900;

    &:hover {
      border-color: $gray300;
      color: $gray900;
    }
  }
}

.tag-button {
  display: flex;
  align-items: center;
  border: 1px solid $gray100;
  background-color: $white;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;

  .icon {
    color: $gray500;
  }
  &:hover {
    border-color: $primary;

    .tag-button__text,
    .icon {
      color: $primary;
    }
  }

  &.active {
    border-color: $primary;
    background-color: $primary;

    .tag-button__text,
    .icon {
      color: $white;
    }
  }

  &__text {
    color: $black;
    transition: color 0.3s;
  }

  &.xs {
    height: 32px;
    padding: 0 12px 0 10px;

    .tag-button__text {
      font-size: 14px;
      line-height: 20px;
    }

    .icon {
      margin-right: 6px;
    }
  }

  &.md {
    height: 40px;
    padding: 0 14px 0 12px;

    .tag-button__text {
      font-size: 16px;
      line-height: 24px;
    }

    .icon {
      margin-right: 6px;
    }
  }

  &.lg {
    height: 42px;
    padding: 0 16px 0 14px;

    .tag-button__text {
      font-size: 18px;
      line-height: 26px;
    }

    .icon {
      margin-right: 8px;
    }
  }
}
