.sidebar {
  position: relative;
}

.sidebar.unauth {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 556px;
  height: 100vh;
  overflow: auto;
  background-color: $white;

  @media (max-width: 1919px) {
    width: 476px;

    .btn {
      height: 44px;
    }
  }

  .sidebar {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__box {
      padding: 32px 64px;

      @media (max-width: 1919px) {
        padding: 24px 48px;
      }
    }

    &-logo {
      display: inline-flex;
      width: 172px;

      @media (max-width: 1440px) {
        width: 136px;
      }

      &__img {
        width: 100%;
        height: auto;
      }
    }

    &__signin {
      min-width: 118px;

      @media (max-width: 1440px) {
        min-width: 88px;
      }
    }

    &__wrapper {
      margin: 86px 0;

      @media (max-width: 1440px) {
        margin: 55px 0;
      }
    }

    &__title {
      font-weight: 700;
      font-size: 49px;
      line-height: 52px;
      letter-spacing: -1.5px;
      color: $black;

      @media (max-width: 1440px) {
        font-size: 36px;
        line-height: 44px;
      }
    }

    &__text {
      margin-top: 8px;
      font-size: 18px;
      line-height: 26px;
      color: $gray700;
    }

    &__buttons {
      margin-top: 24px;

      .btn {
        margin-top: 12px;
      }
    }

    &-list {
      &-item {
        display: flex;
        align-items: flex-start;

        + .sidebar-list-item {
          margin-top: 36px;
        }

        @media (max-width: 1440px) {
          + .sidebar-list-item {
            margin-top: 24px;
          }
        }

        &__title {
          font-weight: 600;
          font-size: 21px;
          line-height: 28px;
          letter-spacing: -0.5px;
          color: $black;

          @media (max-width: 1440px) {
            font-size: 18px;
            line-height: 26px;
          }
        }

        &__text {
          margin-top: 8px;
          font-size: 18px;
          line-height: 26px;
          color: $gray700;

          @media (max-width: 1440px) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        &__icon {
          margin-top: 10px;

          @media (max-width: 1440px) {
            width: 36px;
            height: auto;
          }
        }

        &__inner {
          margin-left: 24px;
        }
      }
    }
  }
}

.sidebar.auth {
  transition: all 250ms ease-out;
  flex-shrink: 0;
  width: 232px;
  background-color: $white;
  z-index: 14;

  .sidebar__box {
    display: flex;
  }

  .sidebar-logo {
    display: inline-flex;
    width: 130px;

    &__img {
      width: 100%;
      height: auto;
    }
  }

  .sidebar-nav {
    display: flex;
    flex-direction: column;
    margin: 32px 0;

    .tab {
      margin: 0 -12px;

      + .tab {
        margin-top: 12px;
      }
    }

    &__link {
      &.active {
        color: $primary;
        background-color: $primary100;
      }

      .icon {
        margin-right: 12px;
      }
    }
  }

  .collapse-btn {
    position: absolute;
    top: 26px;
    right: -14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background: $white;
    border: 1px solid $gray100;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(10, 9, 18, 0.05);
    border-radius: 18px;
    color: $gray900;
    cursor: pointer;
    transition: all 150ms ease-out;
    opacity: 1;

    svg {
      width: 14px;
      height: 14px;
    }

    &:hover {
      border: 1px solid $primary300;
      box-shadow: 0 4px 48px rgba(70, 62, 174, 0.08);
      color: $primary700;
    }
  }

  &.sidebar-collapse {
    width: 116px;
    background-color: $grayPurple;

    .sidebar-collapse {
      margin-left: -232px;
    }

    .sidebar-collapse-btn {
      opacity: 0;
    }
  }

  .sidebar-collapse {
    padding: 26px;
    width: 232px;
    transition: all 250ms ease-out;
    overflow: auto;
    height: 100vh;
  }

  .sidebar-collapsed {
    padding: 26px;
    width: 116px;

    .sidebar-nav {
      opacity: 0;
    }

    .collapse-btn {
      right: 24px;
    }

    .sidebar-logo {
      width: 30px;
    }

    .sidebar-logo__img {
      width: 30px;
      height: 30px;
    }
  }

  .sidebar-sub-nav-list {
    border-top: 1px solid $gray100;
    padding-top: 32px;

    .sidebar-sub-nav-title {
      display: flex;
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 11px;
      line-height: 12px;
      text-transform: uppercase;
      color: $black;
    }
  }

  .sidebar-sub-nav {
    width: 100%;

    .tab {
      margin: 0 -12px;
      margin-top: 12px;

      &.active,
      &:hover {
        .icon {
          color: $primary;
        }
      }
    }
  }
}

.sidebar-course {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 232px;
  height: 100vh;
  padding: 26px;
  background-color: $grayPurple;

  .sidebar {
    &-logo {
      display: inline-flex;
      width: 130px;

      &__img {
        width: 100%;
        height: auto;
      }
    }
  }

  .timeline-container {
    margin-top: 85px;
  }
}

.right-sidebar {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 300px;
  position: sticky;
  top: 92px;
  padding: 8px 8px 0 8px;
  align-self: flex-start;
  overflow-y: auto;
  height: 100%;

  @media (max-width: 1560px) {
    width: 224px;
  }

  .right-sidebar__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $black;

    .right-sidebar-filter-btn {
      cursor: pointer;
      color: $gray400;
    }
  }

  .right-sidebar__item {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 28px;

    .right-sidebar__items {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &:not(:first-child) {
      padding-top: 28px;
      border-top: 1px solid $gray200;
    }

    //TODO: refactor to original tag
    .tab {
      width: 100%;
    }
  }

  .show-more-btn {
    width: 100%;
    justify-content: left;
    margin-top: 20px;
    padding: 0 12px;
  }
}
