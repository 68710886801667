%badge-xs {
  border-radius: 10px;
  padding: 3px 7px;
  .badge__text {
    font-size: 11px;
    line-height: 12px;
    font-weight: 600;
  }

  .badge__icon {
    width: 12px;
    height: 12px;
  }
}

%badge-sm {
  border-radius: 14px;
  padding: 2px 8px;
  .badge__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .badge__icon {
    width: 14px;
    height: 14px;
  }
}
%badge-md {
  border-radius: 14px;
  padding: 3px 12px;
  .badge__text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .badge__icon {
    width: 16px;
    height: 16px;
  }
}

.badge {
  display: flex;
  align-items: center;
  width: max-content;

  &.red {
    background-color: $accent;
    .badge__text {
      color: $white;
    }
    .badge__icon {
      color: $white;
    }
  }

  &.green {
    background-color: #daece4;
    .badge__text {
      color: #50aa7f;
    }
  }

  &.gray {
    background-color: $gray100;
    .badge__text {
      color: $gray700;
    }
  }

  &.violet {
    background-color: $primary200;
    .badge__text {
      color: $primary;
    }
  }

  &__icon {
    margin-left: 4px;
  }

  &.xs {
    @extend %badge-xs;
  }

  &.sm {
    @extend %badge-sm;
  }
  &.md {
    @extend %badge-md;
  }
}
