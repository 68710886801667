.modal-comment {
  .ant-drawer-header {
    border-bottom: none;
    padding: 24px 24px 0 24px;
    color: $black;

    .ant-drawer-title {
      font-weight: bold;
      font-size: 28px;
      line-height: 36px;
    }

    .ant-drawer-close {
      width: 20px;
      height: 20px;
      padding: 0;
      top: 50%;
      right: 32px;
      color: $gray900;
    }
  }
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
  }
  &.mobile {
    .ant-drawer-content-wrapper {
      width: 100%!important;
    }
  }
  .modal-add-comment__tab {
    border-bottom: 1px solid $gray200;
    height: 32px;
  }
}
.modal-add-comment {
  padding: 24px 24px 0 24px;
  flex-shrink: 0;
  flex-grow: 1;
  .modal-add-comment__header {
    display: flex;
    flex-direction: column;

    .modal-add-comment__input {
      display: flex;
      align-items: flex-start;
    }

    .modal__buttons {
      margin-top: 12px;
    }
  }

  .input-comment {
    margin-left: 16px;
    min-height: 44px !important;
    padding: 10px 16px;
    border: 1px solid $gray100;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $black;

    &::placeholder {
      color: $gray400;
    }
    &:focus {
      border: 1px solid #463eae;
      box-shadow: none;
    }
    &:hover {
      border: 1px solid #463eae;
    }
  }
}

.modal-add-comment__tab {
  margin-top: 20px;
}

.tab-label-list.modal {
  position: relative;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: $gray200;
  }

  .tab-label {
    background: none;
    width: 100%;
  }
}
.modal-comment__list {
  height: 100%;
  overflow-y: auto;
  padding: 0 24px;
}
.modal-comment__item {
  margin-top: 32px;
  display: flex;
  width: 100%;
  &:last-child {
    margin-bottom: 24px;
  }

  .modal-comment-item__comment {
    margin-left: 16px;
    width: 100%;
  }
  .modal-comment-item__comment-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .modal-comment-item__comment-info-author {
      display: flex;
      flex-direction: column;
    }

    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $black;
    }
    .date {
      margin-top: 4px;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $gray500;
    }
  }

  .modal-comment__item__content {
    margin-top: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }
}
