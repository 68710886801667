@import "article_classic";
@import "article_related";
@import "article_progress";
@import "articles_size";
@import "article_opened";

.article-item {
  display: flex;
  flex-shrink: 0;
  cursor: pointer;

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    overflow: hidden;
  }

  &__content {
    position: relative;

    &-absolute {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-image {
      border-radius: 16px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-bookmark {
      display: inline-flex;
      padding: 20px 20px 0 0;
      justify-content: flex-end;

      .bookmark-container {
        color: $white;
      }

      .bookmark-filled {
        color: $white;
      }
    }

    &-type {
      display: inline-flex;
      padding: 0 0 20px 20px;
      justify-content: flex-start;

      &:not(.xl) {
        padding: 0 0 16px 16px;
      }

      .tag-label {
        padding: 6px 12px;

        &.mandatory-article-label {
          padding: 4px 8px;
        }
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    &-body__headers {
      display: flex;
      padding: 0 16px;
      flex-direction: column;
      align-items: flex-start;
    }

  }

  &__header-user {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    overflow: hidden;

    .user-name {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: $neutural-black;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
