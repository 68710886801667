%swiper-arrow-before {
  &:before {
    content: '';
    position: absolute;
    width: 228px;
    height: 100%;
    top: auto;
    background: radial-gradient(
      100% 400% at 100% 50%,
      $grayPurple 49.72%,
      rgba(242, 242, 245, 0) 57.77%
    );
  }
  &:after {
    font-size: 16px;
    z-index: 2;
    color: $black;
    position: absolute;
    top: auto;
  }
}

.swiper__landing-page {
  display: flex;
  position: sticky;
  top: 0;
  height: 114px;
  background-color: $grayPurple;
  z-index: 3;
  margin-left: -84px;
  width: calc(100% + 168px);
  padding: 0 84px;

  .swiper-container {
    overflow: initial;
  }
}

.swiper-slide {
  width: auto !important;
}

.swiper-container-block {
  display: flex;
  align-items: center;
  width: 100%;

  .swiper-button-disabled {
    display: none;
  }
}

.swipe-tags {
  --swiper-navigation-size: 16px;
  position: relative;
  height: 100%;
  top: 0;
  overflow: initial;

  .swiper-button-prev {
    height: 100%;
    top: 0;
    margin-top: unset;
    left: -24px;
    @extend %swiper-arrow-before;
    &:before {
      left: -60px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
  .swiper-button-next {
    height: 100%;
    top: 0;
    margin-top: unset;
    right: -24px;
    @extend %swiper-arrow-before;
    &:before {
      right: -60px;
    }
  }
}

.swipe-related-articles {
  padding: 0 84px!important;
  width: calc(100% + 168px);
  left: -84px;

  .swiper-button-prev {
    height: 100%;
    top: 0;
    margin-top: unset;
    left: 20px;
    @extend %swiper-arrow-before;
    &:before {
      width: 84px;
      left: -20px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      background: linear-gradient(270deg, $grayPurple 84.1%, rgba(242, 242, 245, 0) 100%);
    }
    &:after {
      top: 98px;
    }
  }
  .swiper-button-next {
    height: 100%;
    top: 0;
    margin-top: unset;
    right: 20px;
    @extend %swiper-arrow-before;
    &:before {
      width: 84px;
      right: -20px;
      background: linear-gradient(270deg, $grayPurple 84.1%, rgba(242, 242, 245, 0) 100%);
    }
    &:after {
      top: 98px;
    }
    @media (max-width: 1365px) {
      right: -47px;
      &:before {
        right: -47px;
      }
    }
  }
}

.swipe-timeline-item {
  .swiper-button-prev {
    &:before {
      content: '';
      position: absolute;
      width: 66px;
      height: 44px;
      left: -44px;
      top: 0;
      background: radial-gradient(
        100% 2789.16% at 100% 50%,
        $white 48.95%,
        rgba(252, 252, 253, 0) 100%
      );
      transform: rotate(-180deg);
    }
  }
  .swiper-button-next {
    &:before {
      content: '';
      position: absolute;
      width: 100px;
      height: 44px;
      right: -44px;
      top: 0;
      background: radial-gradient(
        100% 2789.16% at 100% 50%,
        $white 48.95%,
        rgba(252, 252, 253, 0) 100%
      );
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    &:after {
      content: unset;
    }
  }
}
