.demo-modal {
  &__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: $black;
  }

  &__text {
    margin-top: 4px;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }

  &__form {
    margin-top: 16px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    .btn + .btn {
      margin-left: 12px;
    }
  }
}
