.profile-page.mobile {
  .profile {
    &__container {
      max-width: 100%;
    }

    &__inner {
      position: relative;
      display: flex;
      flex-direction: column;
      left: -16px;
      width: calc(100% + 32px);

      &-content {
        .masonry-articles-list {
          margin-top: 24px;
          flex-direction: column;
          align-items: center;
          .masonry-articles-col {
            + .masonry-articles-col {
              margin-top: 16px;
            }
          }
        }
      }

    }

    &-cover {
      height: 112px;
      background-position: center;
      background-size: cover;
      width: calc(100% + 32px);
      left: -16px;
      position: relative;
      background-color: #50aa7f;

      .btn {
        position: static;
        min-width: 142px;
      }
    }

    &-sidebar-container {
      display: flex;
      position: relative;
      top: -42px;
      margin-bottom: -42px;
    }

    &-sidebar {
      position: relative;
      width: 100%;
      overflow: hidden;
      padding: 0 16px;

      &-user {
        margin: 0;
        padding: 0;
      }

      &__header {
        position: relative;

        &-mobile {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          position: relative;
          width: 100%;
          margin-bottom: 20px;
        }
      }

      &__name {
        margin-top: 12px;
        font-weight: 600;
        font-size: 21px;
        line-height: 28px;
        letter-spacing: -0.5px;
        color: $black;
      }

      &__position {
        margin-top: 4px;
        font-size: 16px;
        line-height: 24px;
        color: $gray700;
      }

      &__social {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 62px;
        position: absolute;
        right: 0;

        .btn {
          min-width: 92px;
        }

        .btn,
        .btn-icon {
          margin: 0 6px;
        }
      }

      &__follows {
        display: flex;
        justify-content: left;
        align-items: center;

        .people-follow {
          + .people-follow {
            margin-left: 16px;
          }
        }
      }

      &-description {
        margin-top: 20px;
        font-size: 16px;
        line-height: 24px;
        color: $black;
      }

      &-tags {
        margin-top: 20px;
        left: -16px;
        width: calc(100% + 32px);
        position: relative;
        overflow: hidden;

        .swiper-slide:first-child {
          margin-left: 16px;
        }
        .swiper-slide:last-child {
          margin-right: 16px;
        }
      }


      &__footer {
        display: flex;
        flex-direction: column;
        .btn {
          width: 100%;
          min-width: 154px;

          + .btn {
            margin-top: 12px;
          }
        }
      }
    }
  }
  .profile-sidebar-user-block {
    padding-left: 16px;
    padding-right: 16px;
  }
  .profile-tab-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    background: none;
    border-radius: 0;
    padding: 0;
    height: max-content;
    margin-top: 20px;
    margin-bottom: 16px;

    .tab-label {
      height: 31px;
    }

    &__dropdown-items {
      min-height: 32px;
      align-items: center;

      .dropdown-content-list {
        width: auto;
        min-width: max-content;
      }
    }

    .swiper-container {
      height: 32px;
      border-bottom: 1px solid $gray200;
    }
  }
  .ant-upload.ant-upload-select-picture-card {
    position: absolute;
    top: 20px;
    right: 20px;
    width: auto;
    margin: 0;
    height: auto;
    border: none;
    background-color: transparent;
  }
}

.ant-modal.mobile .ant-modal-close {
  right: 16px;
  top: 16px;
}

.ant-modal-root .ant-modal.mobile .modal__title {
  top: 0;
  padding: 0;
  font-size: 28px;
}

.profile-filter-modal {
  &-header {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $black;
    padding: 12px 16px;
  }

  .profile-filter-modal-content__tags {
    margin-bottom: 16px;
    .swiper-slide:first-child {
      padding-left: 16px;
    }
    .swiper-slide:last-child {
      padding-right: 16px;
    }
  }

  .profile-filter-modal-content__time {
    border-top: 1px solid $gray100;
  }

  .profile-filter-modal-footer {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    padding: 0 16px;
    .btn + .btn {
      margin-left: 12px;
    }
  }

  .profile-filter-modal-time-item {
    display: flex;
    justify-content: center;
    position: relative;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $gray600;
    height: 58px!important;
    padding-top: 14px;


    &.active {
      color: $primary;
      &:after {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $primary;
        border-radius: 50%;
      }
    }
  }
}


