.popups {
  position: absolute;
  z-index: 5;
  left: 8px;
  top: calc(100% + 8px);
  width: 100%;
  border: 1px solid $gray200;
  padding: 8px;
  background-color: $white;
  box-shadow: 0 4px 32px rgba(10, 9, 18, 0.12);
  border-radius: 12px;

  &__title {
    padding: 0 12px;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    text-transform: uppercase;
    color: $gray600;
    & + .popups__content:not(:empty) {
      margin-top: 10px;
    }
  }

  &__group {
    + .popups__group {
      margin-top: 24px;
    }
  }
}
