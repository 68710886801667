body {
  overflow-y: auto;
  font-family: $font;

  //@media (min-width: 991px) {
  //  min-width: 1440px;
  //}

  .ant-row {
    width: 100%;
    overflow: hidden;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-layout {
    min-height: 100vh;
    background-color: transparent;
    width: 100%;
    overflow: hidden;
  }
}

#root {
  font-family: $font;
}

.load-more {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 368px;
  width: 100%;
  margin: 0 auto 48px auto;

  .btn {
    margin-top: 24px;
  }
}

.button__google {
  padding: 6px 12px;
  gap: 8px;

}

//TODO: remove to another file
%two-lines-dotes {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

%three-lines-dotes {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 3) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
