.article-item__related {
  border-radius: 16px;
  overflow: hidden;

  &.article-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;

    .article-item__content-body {
      gap: 12px;
    }

    .article-item__content-body__headers {
      padding: 0 6px;
    }

    .article-item__content-body__title {
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.5px;
      color: $neutural-black;
      margin: 0;
    }


    .article-item__content-footer {
      display: flex;
      padding: 0 6px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }

    .article-item__content-footer_related {
      gap: 16px;

      .article-item__publish-time {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: 8px;
        color: $gray500;
      }
    }

  }
}

