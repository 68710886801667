.article-item__classic {
  height: max-content;
  &.article-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    border: 1px solid $neutural-gray-100;
    background: $neutural-white;

    .article-item__header {
      display: flex;
      padding: 0 16px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }

    .article-item__content-body__title {
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: -0.5px;
      color: $neutural-black;
      margin: 0;
    }

    .article-item__content-body__description {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: $neutural-gray-600;
      @extend %three-lines-dotes;
    }

    .article-item__content-footer {
      display: flex;
      padding: 0 18px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }

    .article-item__content-footer__peoples {
      display: flex;
      align-items: center;
      gap: 12px;

      .comments-container {
        span {
          color: $black;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }

    .article-item__content-footer__claps {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 8px;

      .claps-container {
        span {
          color: $black;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
}

