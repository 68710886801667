//.cards-grid {
//  position: relative;
//  display: grid;
//  grid-column-gap: 16px;
//  grid-row-gap: 24px;
//  grid-template-columns: repeat(auto-fill, minmax(368px, max-content));
//  margin: 32px 0;
//
//  @media (max-width: 1920px) {
//    grid-template-columns: repeat(auto-fill, minmax(336px, max-content));
//  }
//}
//
//.mobile {
//  .cards-grid {
//    margin: 20px 0 36px 0;
//    grid-row-gap: 8px;
//    grid-template-columns: 1fr;
//
//    .article-item.xs {
//      width: 100%;
//    }
//
//    .search-card {
//      width: 100%;
//      &:not(:first-child) {
//        margin-top: 12px;
//      }
//      .search-card__bg {
//        height: 108px;
//      }
//    }
//  }
//}
//
//.cards-grid-list-view {
//  display: flex;
//  flex-wrap: wrap;
//  column-gap: 16px;
//  row-gap: 24px;
//
//  .knowledge-base-card {
//    @media (max-width: 1440px) {
//      width: 100%;
//    }
//    display: flex;
//    flex-shrink: 0;
//    width: calc(50% - 8px);
//
//    .knowledge-base-card__content {
//      padding-left: 8px;
//    }
//
//    .knowledge-base-card__bg {
//      width: 168px;
//      @media (max-width: 1440px) {
//        width: 220px;
//      }
//      flex-shrink: 0;
//    }
//
//    .knowledge-base-card__text {
//      -webkit-line-clamp: 2;
//    }
//  }
//
//  .article-item-view-list.xs {
//    padding: 4px;
//    background: #fefefe;
//    border-radius: 12px;
//    cursor: pointer;
//    display: flex;
//    flex-shrink: 0;
//    width: calc(50% - 8px);
//
//    &.article-item-related {
//      .article-item-content__body {
//        display: flex;
//        flex-direction: column;
//        justify-content: center;
//        width: 100%;
//        margin: 0 16px;
//        padding-left: 8px;
//      }
//      .article-item-content__image {
//        width: 180px;
//        height: 116px;
//        img {
//          border-radius: 12px;
//        }
//        &:after {
//          border-radius: 12px;
//        }
//      }
//      .article-item-content__body-title,
//      .article-item-content__body-description{
//        -webkit-line-clamp: 1;
//      }
//      .article-item-content__body-description,
//      .article-item-footer {
//        margin-top: 8px;
//        padding: 0;
//      }
//    }
//
//    @media (max-width: 1440px) {
//      width: 100%;
//      &.article-item-related {
//        .article-item-content__image {
//          width: 220px;
//        }
//      }
//    }
//  }
//}

.cards-grid-list-view {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 24px;

  .knowledge-base-card {
    @media (max-width: 1440px) {
      width: 100%;
    }
    display: flex;
    flex-shrink: 0;
    width: calc(50% - 8px);

    .knowledge-base-card__bg {
      width: 168px;
      @media (max-width: 1440px) {
        width: 220px;
      }
      flex-shrink: 0;
    }

    .knowledge-base-card__text {
      @extend %two-lines-dotes
    }
  }

  .article-item {
    @media (max-width: 1440px) {
      width: 100%;
    }
    display: flex;
    flex-shrink: 0;
    width: calc(50% - 8px);
  }

}

.cards-grid {
  display: flex;
  column-gap: 16px;
  row-gap: 24px;
  width: 100%;
  flex-wrap: wrap;

  .knowledge-base-card {
    width: 100%;
    max-width: 360px;
    height: max-content;
    flex-shrink: 0;

    .knowledge-base-card__text {
      @extend %two-lines-dotes
    }
  }
}
