.field {
  display: flex;
  flex-direction: column;

  &.social {
    .field__input {
      padding-left: 40px;
    }
  }

  + .field {
    margin-top: 16px;
  }

  input::placeholder {
    color: $gray400;
  }

  .field__wrapper {
    position: relative;
  }

  .field__social {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 16px;
    width: 16px;
    height: 16px;
    margin: auto 0;
    color: $gray300;
  }

  .field__label {
    display: inline-flex;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }

  .field__input {
    height: 44px;
    padding: 0 16px;
    border: 1px solid $gray100;
    font-size: 16px;
    color: $black;
    outline: none;
    border-radius: 8px;

    .ant-input-focused,
    &:hover,
    &:focus {
      border-color: $primary;
      box-shadow: none;
      outline: none;
    }
  }

  .ant-picker {
    width: 100%;
    min-height: 44px;
    padding: 8px 16px;
    border: 1px solid #e6e6e7;
    color: #0a0912;
    outline: none;
    border-radius: 8px;

    &:hover,
    &.ant-picker-focused {
      border-color: $primary;
      box-shadow: none;
      outline: none;
    }

    .ant-picker-suffix {
      display: none;
    }

    .ant-picker-input {
      font-size: 16px;
    }

    input::placeholder {
      color: $gray400;
    }
  }

  .field__select {
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      min-height: 44px;
      padding: 8px 16px;
      border: 1px solid $gray100;
      font-size: 16px;
      color: $black;
      outline: none;
      border-radius: 8px;

      .ant-select-focused,
      &:hover,
      &:focus {
        border-color: $primary;
        box-shadow: none;
        outline: none;
      }

      .ant-select-selection-search {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        line-height: 42px;
      }

      .ant-select-selection-overflow,
      .ant-select-selection-overflow-item,
      .ant-select-selection-item {
        line-height: initial;
      }

      .ant-select-selection-placeholder {
        color: $gray400;
      }

      .ant-select-selection-item-remove {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .ant-select-arrow {
      color: $gray900;
    }

    &.ant-select-open {
      transition: transform 0.3s;

      .ant-select-arrow {
        transform: rotate(180deg);
        color: $primary;
      }
    }
  }

  &__prompt,
  &__error {
    margin-top: 8px;
    margin-left: 8px;
    font-size: 14px;
    line-height: 20px;
  }

  &__prompt {
    color: $gray400;
  }

  &__error {
    color: $red;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: $primary !important;
  box-shadow: none !important;
  outline: none !important;
}

.field .ant-select-multiple .ant-select-selection-placeholder {
  left: 16px;
  right: 16px;
}

.ant-select-multiple .ant-select-selector .ant-select-selection-item-content {
  display: flex;
  align-items: center;
}

.field  .ant-select-multiple .ant-select-selection-search {
  -webkit-margin-start: 0;
  margin-inline-start: 0;
}
