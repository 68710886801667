.knowledge-base {
  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 272px 0;
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: $gray300;
  }

  &-card {
    padding: 4px;
    background: $white;
    border-radius: 12px;
    cursor: pointer;

    &.lg {
      width: 368px;
      flex-shrink: 0;
    }

    &.md {
      width: 336px;
      flex-shrink: 0;
    }

    &__bg {
      height: 116px;
      background-position: center;
      background-size: cover;
      border-radius: 8px;
    }

    &__content {
      margin: 16px;
    }

    &__title {
      font-weight: 600;
      font-size: 21px;
      line-height: 28px;
      letter-spacing: -0.5px;
      color: $black;
    }

    &__text {
      margin-top: 4px;
      font-size: 18px;
      line-height: 26px;
      color: $gray600;
      @extend %three-lines-dotes;
    }
  }

  &-folder {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;

    &-banner {
      position: relative;
      width: 100%;
      height: 336px;
      border-radius: 12px;
      background-position: center;
      background-size: cover;

      &__text {
        position: absolute;
        bottom: 32px;
        left: 32px;
        font-weight: 600;
        font-size: 49px;
        line-height: 52px;
        letter-spacing: -1.5px;
        color: $white;
      }
    }

    &__description {
      padding: 0 8px;
      font-size: 21px;
      line-height: 28px;
      letter-spacing: -0.5px;
      color: $gray600;
    }
  }
}

.mobile {
  .knowledge-base-card.md {
    width: 100%;
  }

  .knowledge-base-card__bg {
    height: 108px;
  }

  .knowledge-base__empty {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    padding: 56px 16px;
  }

  .knowledge-base-folder {
    position: relative;
    width: calc(100% + 32px);
    left: -16px;
  }

  .knowledge-base-folder-banner {
    height: 124px;
    border-radius: unset;

    &__text {
      bottom: 20px;
      left: 16px;
      font-size: 28px;
      line-height: 36px;
    }
  }

  .knowledge-base-folder__description {
    margin: 16px 16px 20px 16px;
    padding: 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
  }
}
