.page.mobile {
  .login {
    padding: 24px 0;
  }

  .login-logo {
    width: 124px;
  }

  .login__wrapper {
    min-width: auto;
    margin-top: 12px;
    padding: 24px 16px;
  }

  .login__inner {
    width: 100%;
  }

  .login__title {
    font-size: 20px;
    line-height: 28px;
  }

  .login-organization {
    padding: 12px;
  }

  .login-organization__logo {
    width: 62px;
    height: 62px;
  }
}
