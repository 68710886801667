.sidebar.auth.mobile {
  width: 100%;
  padding: 12px 16px;

  .sidebar-logo {
    display: block;
    margin: 0 auto;
  }

  .tab {
    margin: 0;
  }
}

.modal-sidebar {
  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-close {
    top: 16px;
    left: 16px;
    right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding: 0;
    --scroll-bar: none !important;
    color: $black
  }
}

.right-sidebar-feed-page-modal.ant-modal .tab-label-list {
  margin-bottom: 14px;
  padding-top: 0;
}
