.profile-page.desktop {
  .profile {
    &__container {
      max-width: 1264px;
      width: 100%;
      margin: 0 auto;
    }

    &__inner {
      position: relative;
      display: flex;
      margin: 0 32px 20px 32px;

      @media (max-width: 1566px) {
        margin: 0 20px 20px 20px;
      }

      &-content {
        width: 100%;
        position: relative;
        margin-left: 24px;
        margin-top: -28px;
        @media (max-width: 1566px) {
          margin-left: 16px;
        }
      }

      .masonry-articles-list {
        @media (max-width: 1666px) {
          flex-direction: column;

          .masonry-articles-col {
            width: 100%!important;

            + .masonry-articles-col {
              margin-top: 16px;
            }

            .article-item.xlg {
              width: 100%;
            }
          }
        }
      }
    }

    &-cover {
      position: sticky;
      top: calc(-376px + 52px);
      background-color: #50aa7f;
      width: 100%;
      height: 376px;
      border-radius: 16px;
      background-position: center;
      background-size: cover;
      z-index: 3;

      &__loader {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
      }

      .btn {
        position: absolute;
        top: 20px;
        right: 20px;
        min-width: 166px;
      }
    }

    &-sidebar-container {
      display: flex;
      flex-shrink: 0;
      position: sticky;
      z-index: 4;
      margin-top: -180px;
      min-width: 400px;
      max-width: 400px;
      border-radius: 16px;
      height: max-content;
      top: 20px;
      align-self: flex-start;

      &.max-height {
        top: unset;
        bottom: 20px;
        align-self: flex-end;
      }

      @media (max-width: 1566px) {
        min-width: 336px;
        max-width: 336px;
      }
    }

    &-sidebar {
      width: 100%;
      background-color: $white;
      padding: 32px 24px;
      text-align: center;
      border-radius: 16px;
      height: max-content;

      &__avatar {
        width: 136px;
        height: 136px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
      }

      &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__name {
        margin-top: 16px;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.5px;
        color: $black;
      }

      &__position {
        margin-top: 8px;
        font-size: 18px;
        line-height: 26px;
        color: $gray700;
      }

      &__social {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 32px 0;

        .btn {
          min-width: 130px;
        }

        .btn,
        .btn-icon {
          margin: 0 6px;
        }
      }

      &__follows {
        display: flex;
        justify-content: center;
        align-items: center;

        .people-follow {
          margin: 0 10px;
        }
      }

      &-description {
        margin-top: 32px;
        font-size: 16px;
        line-height: 24px;
        color: $black;
      }

      &-tags {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        max-height: 88px;
        margin: 26px -6px -6px -6px;
        overflow: hidden;

        &.visible {
          max-height: 100%;
          overflow: visible;
        }

        .tag-button {
          margin: 6px;
        }

        &__amount {
          position: absolute;
          right: 0;
          bottom: 6px;
          text-align: right;
          width: 80px;
          height: 36px;
          padding: 6px;
          border: none;
          background: radial-gradient(
                          100% 493.83% at 100% 50%,
                          $white 78.46%,
                          rgba(254, 254, 254, 0) 100%
          );
          font-size: 16px;
          line-height: 24px;
          color: $primary;
          cursor: pointer;
        }
      }


      &__footer {
        display: flex;
        align-items: center;
        margin-top: 16px;

        @media (max-width: 1566px) {
          flex-wrap: wrap;
          .btn {
            width: 100%;

            + .btn {
              margin-top: 12px;
            }
          }
        }

        .btn {
          min-width: 154px;
          margin: 0 8px;
        }
      }
    }
  }

  .ant-upload.ant-upload-select-picture-card {
    position: absolute;
    top: 20px;
    right: 20px;
    width: auto;
    margin: 0;
    height: auto;
    border: none;
    background-color: transparent;

    .btn {
      position: static;
    }
  }

  .ant-upload-list-picture-card-container {
    position: absolute;
    top: 80px;
    right: 20px;
  }
}

.ant-modal-body-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .tab {
    position: relative;
    width: 100%;
    padding: 4px 0;
    height: 64px;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 90%;
      height: 1px;
      background-color:  $gray100;
    }
  }
}

.profile-empty-tab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 224px 24px;
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: $gray300;
}

.profile-sidebar {
  &-user {
    margin-top: 16px;

    &-block {
      display: flex;
      align-items: center;
      text-align: left;
      padding: 16px 8px;

      + .profile-sidebar-user-block {
        border-top: 1px solid $gray100;
      }

      .icon {
        margin-right: 20px;
        color: $gray500;
      }

      &__label {
        font-size: 14px;
        line-height: 20px;
        color: $gray500;
      }

      &__value {
        margin-top: 2px;
        font-size: 16px;
        line-height: 24px;
        color: $gray900;
      }
    }
  }
}

.profile-page:not(.mobile) {
  .profile-tab-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 21px;
    height: 52px;
    width: 100%;
    margin-bottom: 24px;
    background: $white;
    border-radius: 8px;
    padding: 17px 8px 0 8px;
    z-index: 3;

    &.shadow {
      box-shadow: 0 1px 32px rgba(10, 9, 18, 0.16);
    }


    .tab-label {
      height: 34px;
    }

    @media (max-width: 1566px) {
      .tab-label,
      .dropdown-btn__name {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.profile-tab-bar {
  .mobile-modal__dropdown-items {
    display: flex;
    align-items: flex-start;
    height: 100%;

    .popups {
      + .popups {
        margin-left: 8px;
      }
    }
  }
}

.mobile-modal__dropdown-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  position: fixed;
  right: 16px;
  bottom: 20px;
  background-color: $white;
  border: 1px solid $gray100;
  box-sizing: border-box;
  box-shadow: 0 2px 16px rgba(10, 9, 18, 0.06);
  border-radius: 24px;
  color: $black;
  z-index: 4;
  //z-index: 10001;

  &.selected {
    color: $primary;
  }

  &.active {
    background-color: $primary900;
    color: $white;
    border: none;
  }

  &.text {
    width: max-content;
    padding: 0 20px;
    right: 76px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  .badge {
    position: absolute;
    min-height: 20px;
    padding: 0 7px;
    top: -5px;
    right: -5px;
  }
}

.people-follow {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  cursor: pointer;

  &__amount {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }

  &:hover {
    .people-follow__amount {
      color: $gray700;
    }
  }
  &.row {
    .people-follow__amount {
      margin-left: 12px;
    }
  }
  &.col {
    flex-direction: column;
    .people-follow__amount {
      margin-top: 8px;
    }
  }
}
