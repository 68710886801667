.header.mobile {
  position: sticky;
  z-index: 10;
  top: 0;
  margin: 0 -16px 12px -16px;
  background-color: $grayPurple;

  &.shadow {
    box-shadow: 0 1px 32px rgba(10, 9, 18, 0.16);
  }

  .header__inner {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 16px;
  }

  .header-logo {
    width: 120px;

    &__img {
      width: 100%;
      height: auto;
    }
  }

  .header__controls {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 8px;
  }

  .header-search {
    .btn-icon.xs {
      width: max-content;
      &:hover,
      &.active {
        background-color: transparent;
      }
    }
  }

  .header-notification {
    .btn-icon.ant-dropdown-open {
      background-color: $primary200;
    }

    .icon {
      width: 20px;
      height: 20px;
    }

    &-status {
      display: flex;
      align-items: center;
      padding: 12px 12px 4px 12px;

      &.new {
        .header-notification-status__label {
          color: $accent;
        }

        .header-notification-status__line {
          background-color: $accent;
        }
      }

      &.earlier {
        .header-notification-status__label {
          color: $gray400;
        }

        .header-notification-status__line {
          background-color: $gray400;
        }
      }

      &__label {
        font-weight: 600;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      &__line {
        width: 100%;
        height: 1px;
        margin-left: 6px;
      }
    }


    &-item {
      display: flex;
      align-items: center;
      padding: 8px 12px 12px 12px;

      .btn {
        margin-left: auto;
      }

      &__inner {
        margin: 0 12px;
      }

      &__icon {
        margin-top: 4px;
        margin-bottom: auto;
      }

      &__avatar {
        position: relative;
        min-width: 20px;
        height: 20px;
        border-radius: 6px;
        background-position: center;
      }

      &__title {
        font-size: 16px;
        line-height: 24px;
        color: $black;

        a {
          color: $primary;

          &:hover {
            color: $primary600;
          }
        }
      }

      &__text {
        font-size: 14px;
        line-height: 20px;
        color: $gray400;
      }
    }
  }

  .ant-dropdown-trigger {
    .avatar {
      margin: 0 6px 0 0;
    }
  }

  .header__burger {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    padding: 0;
    border: none;
    background-color: transparent;
  }

  .search__inner {
    min-width: auto;
  }

  .search {
    margin: 0 16px;
  }

  .dropdown.notification {
    position: static;

    .dropdown-content-list {
      top: 56px;
      width: calc(100% - 32px);
    }
  }

  .dropdown-account .dropdown-content-list {
    top: 44px;
  }
}

.header-notification-modal-mobile {
  max-width: calc(100% - 32px);
  height: 364px;
  left: 0 !important;
  right: 0;
  margin: 0 auto !important;
  overflow: auto;
}

.ant-dropdown.header-notification-modal-mobile,
.ant-dropdown.header-account-modal-mobile {
  top: 60px !important;
}
