.tab {
  width: 100%;
  padding: 8px 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: $white;
  transition: 0.3s;

  .icon {
    transition: color 0.3s;
  }

  .follow-btn .icon {
    margin-right: 6px;
  }

  &.transparent-gray,
  &.transparent-none {
    .follow-btn {
      &:hover {
        .tab__link {
          color: $primary700;
        }
        .icon {
          color: $primary500;
        }
      }
      &.active {
        .tab__link {
          color: $black;
        }
        .icon {
          color: $gray900;
        }
        &:hover {
          .tab__link,
          .icon {
            color: $gray700;
          }
        }
      }
    }
  }

  &.transparent-gray {
    background-color: transparent;

    &:hover {
      background: $gray100;
    }
  }

  &.transparent-none {
    background-color: transparent;
  }

  &.white-gray,
  &.white-purple {
    .tab__left {
      .icon {
        color: $gray500;
        width: 16px;
      }
    }

    .tab__text {
      transition: color 0.3s;
    }

    &.active,
    &:hover {
      .tab__left {
        .tab__text {
          color: $primary;
        }

        .icon {
          color: $primary;
        }
      }

      .tab__right {
        .tab__link {
          color: $primary800;
        }

        .icon {
          color: $primary500;
        }
      }
    }
  }

  &.white-gray {
    &:hover {
      background: $grayPurple;
    }

    &.active {
      background: $grayPurple;
    }
  }

  &.white-purple {
    &.active,
    &:hover {
      background: $primary100;
    }

    &.search-tab {
      .tab__text {
        color: $gray400;
      }
      .search-highlight {
        color: $black;
      }

      &:hover,
      &.active {
        .tab__text {
          color: $primary500;
        }
        .search-highlight {
          color: $primary;
        }
        .tab__right .icon {
          color: $black;
        }
      }

      & + .search-tab {
        margin-top: 2px;
      }

      &.active {
        background: transparent;
      }
    }
  }

  &.block {
    width: 100%;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__left {
    flex: 1;

    .tab-image {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
    .icon {
      min-width: 16px;
      height: 16px;
      margin-right: 10px;
      color: $gray500;
    }
  }

  &__right {
    margin-left: 12px;

    .icon {
      width: 16px;
      height: 16px;
    }
    .tab__link {
      + .icon {
        margin-left: 4px;
      }
    }
  }

  &__text,
  &__link {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  }

  &__text {
    color: $black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__link {
    color: $primary;
  }

  .avatar {
    margin-right: 12px;
  }
}

.tab-label {
  display: flex;
  justify-content: center;
  position: relative;
  height: 32px;
  width: max-content;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $gray500;
  border-bottom: 1px solid $gray200;
  //transition: all .1s ease-in-out;
  background-color: $grayPurple;
  padding: 0 12px 8px 12px;
  cursor: pointer;
  z-index: 2;

  &.transparent {
    border-bottom: 1px solid transparent;
    background-color: transparent;
  }
  &:hover {
    color: $primary600;
    .badge {
      background-color: $primary500;
    }
  }

  .badge {
    margin: 4px 0 0 8px;
    height: 17px;
    background-color: $gray400;
    .badge__text {
      line-height: 11;
    }
    @media (max-width: 1566px) {
      margin-top: 2px;
    }
  }

  &.active {
    color: $black;
    font-weight: 600;
    border: none;

    &:after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: $primary;
      border-radius: 2px 2px 0 0;
    }
    .badge {
      background-color: $gray900;
    }
  }
}

.tab-label-list {
  display: flex;

  .tab-label {
    + .tab-label {
      margin-left: 8px;
    }
  }
}

.page-template-tabs {
  width: 100%;
  .tab-label-list {
    width: 100%;
  }
}
