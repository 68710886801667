//$primary: #463eae;
//$primary900: #5951b6;
//$primary800: #6b65be;
//$primary700: #7d78c6;
//$primary600: #908bce;
//$primary500: #a39fd7;
//$primary400: #b5b2df;
//$primary300: #c7c5e7;
//$primary200: #dad8ef;
//$primary100: #ececf7;

$accent: #f66c3d;
$accent900: #f77b50;
$accent800: #f88964;
$accent700: #f99877;
$accent600: #faa78b;
$accent500: #fab69e;
$accent400: #fbc4b1;
$accent300: #fcd3c5;
$accent200: #fde2d8;
$accent100: #fef0ec;

$green: #50aa7f;
$green700: #84c3a5;
$green500: #a7d5bf;
$green300: #cae5d9;

$red: #db6868;
$red800: #dd8585;
$red700: #e19797;
$red200: #f7dede;
$red100: #fbefef;

$gray900: #22222a;
$gray800: #3b3a41;
$gray700: #535359;
$gray600: #6c6b71;
$gray500: #848488;
$gray400: #9d9da0;
$gray300: #b5b5b8;
$gray200: #ceced0;
$gray100: #e6e6e7;

$grayOrange: #f6f4f3;

$black: #0a0912;
$white: #fefefe;


/// new colors

$neutural-black: #0A0912;
$neutural-white: #FEFEFE;
$neutural-gray-100: #E6E6E7;
$neutural-gray-200: #CECED0;
$neutural-gray-500: #848488;
$neutural-gray-600: #6C6B71;
$primary100: #ECECF7;
$primary200: #dad8ef;
$primary300: #c7c5e7;
$primary400: #B5B2DF;
$primary500: #A39FD7;
$primary600: #908BCE;
$primary700: #7D78C6;
$primary800: #6B65BE;
$primary900: #5951B6;
$primary: #463EAE;
$grayPurple: #f2f2f5;
