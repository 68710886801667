.btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  background-color: $grayPurple;
  border-radius: 6px;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;

  &:hover:not(.disabled) {
    background-color: $primary200;
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.active {
    background-color: $primary200;

    .icon {
      color: $primary;
    }
  }

  &.xxs {
    width: 32px;
    height: 32px;
  }

  &.xs {
    width: 36px;
    height: 36px;
  }

  &.md {
    width: 44px;
    height: 44px;
  }

  &.lg {
    width: 48px;
    height: 48px;
  }

  .icon {
    transition: color 0.3s;
  }
}
