.material {
  &-modal {
    &__inner {
      display: flex;
      gap: 24px;
      margin-top: 36px;
    }

    &-card {
      width: 100%;
      padding: 24px 48px 32px 48px;
      background-color: $white;
      border: 1px solid $gray100;
      box-shadow: 0 4px 16px rgba(10, 9, 18, 0.03);
      border-radius: 12px;
      text-align: center;
      cursor: pointer;
      transition: border-color 0.3s;

      &:hover {
        border-color: $primary;

        .material-modal-card__icon {
          transform: translateY(-40px);
        }

        .material-modal-card__title {
          color: $primary;
        }
      }

      &__title {
        margin-top: 24px;
        font-weight: 600;
        font-size: 37px;
        line-height: 44px;
        color: $black;
        transition: color 0.3s;
      }

      &__text {
        margin-top: 16px;
        font-size: 21px;
        line-height: 28px;
        letter-spacing: -0.5px;
        color: $gray600;
      }

      &__icon {
        width: 214px;
        height: 176px;
        margin: 0 auto;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform 0.3s;
      }
    }
  }

  &-wrapper {
    .ant-modal-content {
      background-color: $grayPurple;
    }

    .ant-modal-body {
      padding: 48px 84px;
    }
  }

  &-preparing {
    position: relative;
    max-width: 1064px;
    margin: 0 auto;
    padding: 0 24px;

    &__content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    &__left,
    &__center,
    &__right {
      padding-top: 32px;
    }

    &__left {
      min-width: 128px;
      padding-right: 20px;

      .material-preparing__subtitle {
        margin-top: 10px;
      }
    }

    &__center {
      width: 496px;
    }

    &__right {
      display: flex;
      align-items: flex-start;
      margin-left: 20px;

      .material-preparing__subtitle {
        margin-right: 20px;
      }
    }

    &-patterns {
      &__list {
        display: flex;
        width: 176px;
        flex-wrap: wrap;
        margin: 0 -2px 6px -2px;
      }

      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin: 2px;
        border: 2px solid transparent;
        background-color: transparent;
        border-radius: 10px;
        cursor: pointer;
        transition: border-color 0.3s;

        &.checked {
          background-color: $white;
          border-color: $primary;
        }

        &__bg {
          min-width: 32px;
          height: 32px;
          border: none;
          background-size: cover;
          background-position: center;
          border-radius: 6px;
        }
      }

      .radio + .radio {
        margin-top: 10px;
      }
    }

    &-card {
      width: 100%;
      padding: 20px 12px;
      background-color: $white;
      border: 1px solid $gray100;
      box-shadow: 0 4px 48px rgba(10, 9, 18, 0.04);
      border-radius: 16px;

      &-cover {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 276px;
        padding: 88px 24px 20px 24px;
        background-size: cover;
        background-position: center;
        border-radius: 12px;

        .field {
          display: inline-flex;
          margin-top: auto;

          .field__select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
            min-height: 36px;
          }
        }

        .field__select {
          min-width: 230px;
        }
      }

      &__box {
        margin: 20px 0;
      }

      &__title {
        font-weight: 600;
        font-size: 21px;
        line-height: 28px;
        letter-spacing: -0.5px;
        color: $black;
      }

      &__text {
        margin-top: 12px;
        font-size: 16px;
        line-height: 24px;
        color: $gray600;
      }

      &__name {
        font-weight: 700;
        font-size: 49px;
        line-height: 52px;
        letter-spacing: -1.5px;
        color: $white;

        &::placeholder {
          color: $gray400;
        }
      }

      .article-item-footer {
        padding: 0 18px;
      }
    }

    &__wrapper {
      padding: 0 128px;

      .l-2 {
        margin-top: 16px;
      }
    }

    &-background {
      &-box {
        + .material-preparing-background-box {
          margin-top: 12px;
        }

        &__expand {
          margin-left: 28px;
        }

        &__image-name {
          margin-top: 6px;
          text-align: center;
          font-size: 16px;
          line-height: 24px;
          color: $gray500;
        }

        .btn {
          width: 100%;
        }
      }

      .radio {
        margin-bottom: 12px;
      }
    }

    &__section {
      display: flex;

      + .material-preparing__section {
        margin-top: 32px;
        border-top: 1px solid $gray100;
      }
    }

    &__subtitle {
      text-align: right;
      font-size: 16px;
      line-height: 24px;
      color: $black;
    }

    &-selects {
      display: flex;
      align-items: center;

      .field + .field {
        margin-top: 0;
      }

      .field__select {
        min-width: 248px;
      }

      &__to {
        margin: 0 20px;
      }
    }

    &-checkboxes {
      padding-top: 4px;

      .checkbox {
        margin-top: 12px;
      }

      &__description {
        margin: 4px 0 0 28px;
        font-size: 16px;
        line-height: 24px;
        color: $gray400;
      }
    }

    &-topics {
      margin-top: 20px;

      &__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $gray500;
        text-transform: uppercase;
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -6px;

        a {
          margin: 0 6px;
          color: $primary;
          cursor: pointer;
        }
      }
    }

    &-hex {
      position: relative;
      max-width: 112px;

      &__color {
        position: absolute;
        z-index: 1;
        left: 8px;
        top: 0;
        bottom: 0;
        width: 24px;
        height: 24px;
        margin: auto 0;
        border-radius: 6px;
      }

      .field .field__input {
        height: 40px;
        padding: 8px 8px 8px 40px;
      }
    }

    &-footer {
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 172px;
      margin-bottom: -15px;
      padding-bottom: 30px;
      background: linear-gradient(180deg, rgba(254, 254, 254, 0) 0%, $white 62.35%);

      &__inner {
        display: flex;
        align-items: flex-end;
        padding: 0 148px;
        height: 100%;
        margin-top: auto;

        .btn {
          + .btn {
            margin-left: 10px;
          }

          &.primary {
            min-width: 120px;
          }

          &.secondary {
            min-width: 168px;
          }
        }
      }
    }
  }
}

.material-preparing-card-cover {
  .material-preparing-card__formats {
    margin-top: auto;
    padding: 6px 12px;
    background-color: $white;
    border-radius: 8px;
  }
}
