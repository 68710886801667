.xxl-1 {
  font-weight: 600;
  font-size: 87px;
  line-height: 88px;
  letter-spacing: -2.5px;
  color: $black;
}

.xl-1 {
  font-weight: 700;
  font-size: 65px;
  line-height: 68px;
  letter-spacing: -2px;
  color: $black;
}

.xl-2 {
  font-weight: 600;
  font-size: 65px;
  line-height: 64px;
  letter-spacing: -2px;
  color: $black;
}

.xl-3 {
  font-weight: 700;
  font-size: 56px;
  line-height: 60px;
  letter-spacing: -1.5px;
  color: $black;
}

.l-1 {
  font-weight: 700;
  font-size: 49px;
  line-height: 52px;
  letter-spacing: -1.5px;
  color: $black;
}

.l-2 {
  font-weight: 600;
  font-size: 49px;
  line-height: 52px;
  letter-spacing: -1.5px;
  color: $black;
}

.l-3 {
  font-size: 49px;
  line-height: 52px;
  letter-spacing: -1.5px;
  color: $black;
}

.l-4 {
  font-weight: 700;
  font-size: 37px;
  line-height: 44px;
  color: $black;
}

.l-5 {
  font-weight: 600;
  font-size: 37px;
  line-height: 44px;
  color: $black;
}

.m-1 {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: $black;
}

.m-2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
  color: $black;
}

.m-3 {
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
  color: $black;
}

.m-4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.5px;
  color: $black;
}

.m-5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.5px;
  color: $black;
}

.m-6 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: $black;
}

.m-7 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: $black;
}

.m-8 {
  font-size: 21px;
  line-height: 28px;
  letter-spacing: -0.5px;
  color: $black;
}

.s-1 {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: $black;
}

.s-2 {
  font-size: 18px;
  line-height: 32px;
  color: $black;
}

.s-3 {
  font-size: 18px;
  line-height: 26px;
  color: $black;
}

.s-4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.s-5 {
  font-size: 16px;
  line-height: 28px;
  color: $black;
}

.s-6 {
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.s-7 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $black;
}

.s-8 {
  font-size: 14px;
  line-height: 20px;
  color: $black;
}

.xs-1 {
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  color: $black;
}

.xs-2 {
  font-weight: 600;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 1px;
  color: $black;
}

.xs-3 {
  font-size: 11px;
  line-height: 16px;
  color: $black;
}
