.progressbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  &.article {
    .progressbar__text {
      color: $primary;
    }
  }

  &.load {
    .progressbar__text {
      text-align: center;
      color: $gray600;
      margin-bottom: 12px;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $primary;
  }

  &__line-bg {
    width: 100%;
    height: 4px;
    background: $gray100;
    border-radius: 8px;
    overflow: hidden;
  }

  &__line {
    height: 100%;
    background: $primary;
    border-radius: 8px;
  }

  @media (max-width: 1440px) {
    max-width: 60%;
  }
}
