.textarea {
  display: block;
  overflow: hidden;
  resize: none;
  width: 100%;
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;

  &::placeholder {
    color: $gray400;
  }
}
