.landing-page.mobile {
  flex-direction: column;

  .sidebar {
    width: 100%;
    height: auto;
    padding: 0;
    overflow: visible;

    &__box {
      padding: 20px 20px 36px 20px;
    }

    &__wrapper {
      margin: 36px 0;
    }

    &__title {
      font-size: 34px;
      line-height: 40px;
    }

    &__buttons {
      margin-top: 12px;
    }
  }

  .page__landing {
    padding: 0;
  }

  .page__content {
    height: auto;
  }

  .swiper__landing-page {
    width: calc(100% + 32px);
    height: 80px;
    margin-left: -16px;
    padding: 0;

    .swiper-button-prev,
    .swiper-button-next{
      display: none;
    }

    .swiper-slide {
      &:first-child {
        margin-left: 20px;
      }

      &:last-child {
        margin-right: 16px;
      }
    }
  }
}
