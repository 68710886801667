.course-lessons {
  .article-opened__wrapper {
    margin: 0 auto;
  }
  .article-opened-content {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__footer {
    display: flex;
    width: 100%;
    gap: 16px;

    .btn {
      width: 100%;
    }
  }
}
