.radio {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.checked {
    .radio__btn {
      border-color: $primary;
    }

    .radio__circle {
      background-color: $primary;
    }

    .radio__label {
      color: $gray900;
    }
  }

  &.disabled {
    pointer-events: none;

    .radio__btn {
      border-color: $gray300;
    }

    .radio__circle {
      background-color: $primary;
    }
  }

  &:hover {
    .radio__btn {
      border-color: $primary800;
    }

    .radio__circle {
      background-color: $primary800;
    }
  }

  &__input {
    display: none;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border: 2px solid $primary400;
    background-color: transparent;
    border-radius: 50%;
    cursor: inherit;
    transition: border-color 0.3s;
  }

  &__circle {
    min-width: 8px;
    height: 8px;
    background-color: transparent;
    border-radius: 50%;

    transition: background-color 0.3s;
  }

  &__label {
    margin-left: 12px;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    transition: color 0.3s;
  }
}
