.article-item {
  &.sm, &.xs {
    .article-item__content-bookmark {
      padding: 16px 16px 0 0;
    }

    .bookmark-container {
      width: 16px;
      height: 16px;
    }
  }
}

.article-item__classic {
  &.article-item {
    padding: 20px 12px;

    .article-item__wrapper {
      gap: 20px;
    }

    &.xl {
      width: 624px;

      .article-item__content-image {
        width: 600px;
        height: 342px;
      }

      .tag-label {
        padding: 6px 12px;
      }
    }

    &.lg {
      width: 496px;

      .article-item__content-image {
        width: 472px;
        height: 276px;
      }
    }

    &.md {
      width: 424px;
      padding: 16px 8px;

      .article-item__wrapper {
        gap: 16px;
      }

      .article-item__content-image {
        width: 408px;
        height: 234px;
      }
    }

    &.sm {
      width: 343px;
      padding: 16px 8px;

      .article-item__wrapper {
        gap: 16px;
      }

      .article-item__header {
        .btn__text {
          line-height: 20px;
        }
      }

      .article-item__content-image {
        width: 327px;
        height: 192px;
      }
    }

    &.xs {
      width: 336px;
      padding: 16px 8px;

      .article-item__wrapper {
        gap: 16px;
      }

      .article-item__content-image {
        width: 320px;
        height: 188px;
      }
    }
  }
}

.article-item__related {
  .article-item__wrapper {
    gap: 16px;
  }

  &.article-item {
    &.lg {
      width: 368px;

      .article-item__content-image {
        width: 100%;
        height: 218px;
      }
    }

    &.md {
      width: 343px;

      .article-item__content-image {
        width: 100%;
        height: 192px;
      }
    }

    &.sm {
      width: 336px;

      .article-item__content-image {
        width: 100%;
        height: 188px;
      }
    }

    &.xs {
      width: 283px;

      .article-item__content-image {
        width: 100%;
        height: 160px;
      }
    }
  }
}
