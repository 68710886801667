.lesson-quiz {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 201;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  text-align: left;
  background-color: #141821;
  transition: background-color .3s;
  font-family: $font;
}

.lesson-quiz-btn-back {
  position: fixed;
  width: 56px;
  height: 56px;
  left: 24px;
  top: 24px;
  border-radius: 50%;
  background: none;
  overflow: hidden;
  z-index: 2;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F4F5F7;
    opacity: 0.08;
  }

  .icon {
    width: 14px;
    height: 12px;
    color: #F4F5F7;
    opacity: 0.8;
  }
}

.lesson-quiz__cards {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .lesson-quiz__card-wrapper {
    position: relative;
    width: min(calc(100% - 168px), 1000px);
    height: min(calc(100% - 168px), 732px);

    &:not(.quiz-finished) {
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
      }
    }

    &:before {
      background: rgb(208, 209, 211);
      z-index: -1;
      transform: translate(0%, -7%) scale(0.92, 0.92);
    }

    &:after {
      background: rgb(161, 163, 166);
      z-index: -2;
      transform: translate(0%, -14%) scale(0.84, 0.84);
    }
  }
}

.lesson-quiz__card {
  padding: 48px;
  width: 100%;
  height: 100%;
  transform: translate(0%, 0%);
  background: #FFFFFF;
  border-radius: 12px;

  &.starter-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px 120px;

    .lesson-quiz__card-description {
      margin-top: 8px;
    }

    .btn {
      min-width: 175px;
      margin-top: 56px;
    }
  }

  &.finish-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .lesson-quiz__card-inner {
      max-width: 472px;
    }

    .lesson-quiz__card-title {
      font-weight: 400;
      font-size: 36px;
      line-height: 44px;
      color: #141821;
      margin-bottom: 20px;
    }

    .lesson-quiz-btn-back {
      position: absolute;
      top: 32px;
      left: auto;
      right: 32px;
      background: rgba(20, 24, 33, 0.04);
      backdrop-filter: blur(40px);
      border-radius: 28px;

      .icon {
        color: rgba(20, 24, 33, 0.5);;
      }
    }

    .ant-input-affix-wrapper {
      .field__input {
        height: 52px;
      }
    }
    &__failed {
      height: min(100% - 168px, 432px);
    }
  }

  &.regular-card {
    .lesson-quiz__card-header {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 8px;
    }

    .lesson-quiz__card-footer {
      .btn {
        max-width: 236px;
      }
    }
  }

  .lesson-quiz__card-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }

  .lesson-quiz__card-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: rgba(20, 24, 33, 0.5);
  }

  .lesson-quiz__card-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #141821;

    span {
      color: #000000;
      font-weight: 600;
      padding: 0 4px;
    }
  }

  .lesson-quiz__card-content-img {
    //width: 388px;
    //height: 332px;
    height: 28vh;
    border-radius: 12px;
    margin: 48px 0 24px 0;
  }


  .lesson-quiz__card-hearts {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;

    .lesson-quiz__card-hearts-title {
      margin-right: 4px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(20, 24, 33, 0.5);
    }

    .heart-low {
      stroke: #c4c5c7;
      stroke-width: 2px;
      color: white;
    }
    .heart-full {
      stroke: #CC5949;
      stroke-width: 2px;
      color: #CC5949;
    }
  }

  .question-title {
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  .question-description {
    margin-top: 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: rgba(20, 24, 33, 0.5);
  }

  .lesson-quiz__card-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: auto;
    width: 100%;

    .btn {
      flex-grow: 1;
      font-weight: 600;
    }

    .retake-btn {
      .icon {
        width: 16px;
        height: 15px;
      }
    }

    .next-btn {
      .icon {
        width: 14px;
        height: 10px;
      }
    }
  }
}

.quiz__progress-bar {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin: 24px 0 48px 0;

  .quiz__progress-bar-item {
    flex-grow: 1;
    width: 100%;
    height: 3px;
    border-radius: 4px;
    background-color: rgba(20, 24, 33, 0.08);

    &.success {
      background-color: #2769FF;
    }

    &.error {
      background-color: #CC5949;
    }
  }
}

.checkbox-question-item {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.answers-list {
  counter-reset: upper-alpha;
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 40px 0;

  &.answers-list--disabled {
    pointer-events: none;
  }

  .answer-item {
    display: flex;
    align-items: center;
    gap: 16px;
    counter-increment: upper-alpha;
    cursor: pointer;


    .answer-round {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 44px;
      height: 44px;
      border: 1.2px solid #141821;
      border-radius: 50%;

      &:before {
        content: counter(upper-alpha, upper-alpha);
      }
    }

    .answer-label {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #000000;
    }

    &.answer-item--checked {
      .answer-round {
        border: 1.2px solid #463eae;
        background-color: #463eae;
        color: #FFFFFF;
      }

      .answer-label {
        color: #463eae;
      }
    }

    &.answer-item--correct {
      .answer-round {
        border: 1.2px solid #2BB986;
        background-color: #2BB986;
        color: #FFFFFF;

        .icon {
          width: 18px;
          height: 18px;
        }

        &:before {
          content: none;
        }
      }

      .answer-label {
        color: #2BB986;
      }
    }

    &.answer-item--not-selected-correct {
      .answer-round {
        border: 1.2px solid #2BB986;
        background-color: rgba(43, 185, 134, 0.1);
        color: #2BB986;
      }

      .answer-label {
        color: #2BB986;
      }
    }

    &.answer-item--incorrect {
      .answer-round {
        border: 1.2px solid #CC5949;
        background-color: #CC5949;
        color: #FFFFFF;

        .icon {
          width: 12px;
          height: 12px;
        }

        &:before {
          content: none;
        }
      }

      .answer-label {
        color: #CC5949;
      }
    }
  }
}

.answer-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  &.success {
    color: #2BB986;
  }
  &.error {
    color: #CC5949;
  }
}

.finish-review-emoji {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 48px 0;

  .lesson-quiz__card-description {
    margin-bottom: 24px;
  }
}

.finish-review-emoji__list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.finish-review-emoji__item {
  display: flex;
  flex-direction: column;
  align-items: center;

  .finish-review-emoji__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border: 1px solid rgba(20, 24, 33, 0.15);;
    border-radius: 50%;
    cursor: pointer;
    font-size: 32px;
  }

  .finish-review-emoji_label {
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #141821;

  }

  &.active {
    .finish-review-emoji__icon {
      background: rgba(70, 125, 249, 0.08);
      border: 1px solid #2769FF;
      color: #FFFFFF;
    }
  }
}

.lesson-quiz__card-review {
  width: 100%;

  .field .field__input {
    height: 56px;
  }

  .ant-input-suffix {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(20, 24, 33, 0.5);
    position: absolute;
    left: 0;
    bottom: -28px;

    &:before {
      content: 'Character limit';
      padding-right: 4px;
    }
  }
}

.modal_quiz {
  min-width: 562px;

  .ant-modal-body {
    padding: 32px;
  }

  .modal__title {
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: #141821;
    margin-bottom: 12px;
  }

  .modal__description {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: rgba(20, 24, 33, 0.5);
  }

  .modal__buttons {
    margin-top: 28px;

    .btn {
      width: 100%;
    }
  }
}


.lesson-quiz {
  &.mobile {
    display: flex;
    flex-direction: column;

    .lesson-quiz-btn-back {
      width: 40px;
      height: 40px;
    }

    .lesson-quiz__card {
      padding: 24px 20px;
      border-radius: 24px;

      &.starter-card {
        .quiz-footer-btn {
          display: flex;
          align-items: flex-end;
          height: 100%;
        }

        .btn {
          margin-top: 0;
          height: max-content;
        }
      }

      .lesson-quiz__card-inner {
        justify-content: flex-start;
      }

      .lesson-quiz__card-description {
        font-size: 16px;
        line-height: 24px;
      }

      .lesson-quiz__card-content-img {
        width: 100%;
        height: auto;
        max-width: max-content;
        max-height: 50vh;
        margin: 0 0 12px 0;
      }
    }

    .lesson-quiz__cards {
      padding-top: 100px;
    }

    .lesson-quiz__card-wrapper {
      width: min(100% - 16px, 1000px);
      height: min(100% - 16px, 732px);

      &:not(.quiz-finished) {
        &:before,
        &:after {
          border-radius: 24px;
        }
      }

      &:before {
        transform: translate(0%, -6%) scale(0.92, 0.92);
      }

      &:after {
        transform: translate(0%, -12%) scale(0.84, 0.84);
      }
    }

    .lesson-quiz__card-description-mobile {
      position: fixed;
      top: 24px;
      left: 72px;
      height: 40px;
      width: calc(100% - 88px);
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .lesson-quiz__card-header {
      position: absolute;

      .lesson-quiz__card-hearts-title {
        display: none;
      }
    }

    .lesson-quiz__card-hearts {
      top: -134px;
      left: -8px;
    }

    .quiz__progress-bar {
      position: absolute;
      top: -64px;
      margin: 0;

      .quiz__progress-bar-item {
        background-color: rgba(244, 245, 247, 0.15);

        &.success {
          background-color: #FFFFFF;
        }

        &.error {
          background-color: #CC5949;
        }
      }
    }

    .lesson-quiz__card {
      .question-title {
        font-size: 21px;
        line-height: 28px;
      }

      .question-description {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .answer-round {
      width: 36px;
      height: 36px;
    }

    .answer-label {
      font-size: 16px;
      line-height: 24px;
    }

    &.finished {
      .lesson-quiz__cards {
        padding-top: 0;
      }

      .quiz-finished {
        width: 100%;
        height: 100%;
      }

      .finish-card {
        border-radius: 0;
      }

      .lesson-quiz__card-title {
        font-size: 24px;
        line-height: 28px;
        margin-top: 6px;
      }

      .lesson-quiz-btn-back {
        top: 24px;
      }

      .finish-review-emoji__list {
        gap: 12px;
      }

      .finish-review-emoji__icon {
        width: 54px;
        height: 54px;
        font-size: 24px;
      }

      .ant-input-affix-wrapper .field__input {
        height: 46px;
      }

      .field__input {
        height: 48px;
      }

      .lesson-quiz__card-footer {
        flex-wrap: wrap;

        .btn {
          width: 100%;
        }
      }
    }

    &.regular {
      .lesson-quiz__card-description-mobile {
        width: calc(100% - 188px);
      }

      .lesson-quiz__cards {
        padding-top: 135px;
      }
    }
  }
}

.ant-modal-root {
  .modal_quiz {
    &.mobile {
      min-height: 316px;
      height: 48vh;
      min-width: 100%;

      .modal__title {
        position: relative;
        padding-top: 0;
        width: 100%;
        height: max-content;
        border: none;
        font-size: 28px;
        line-height: 36px;
      }

      .modal__description {
        font-size: 18px;
        line-height: 28px;
      }

      .ant-modal-body {
        display: flex;
        flex-direction: column;
        padding: 32px 24px 24px 24px;
      }

      .ant-modal-content {
        border-radius: 12px;
      }

      .modal__buttons {
        align-items: flex-end;
        flex-grow: 1;
        flex-direction: column;
        gap: 12px;

        .btn {
          width: 100%;

          &.primary {
            order: -1;
          }
        }
      }
    }
  }
}

@keyframes confetti-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes confetti-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
#confetti-holder {
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  animation: confetti-show 1s ease-out;

  &.active {
    z-index: 203;
    animation: confetti-hide 4s ease-in;
  }
}
