.page__cms {
  width: 100%;

  &.mobile {
    .add-material-container {
      padding: 24px;
    }

    .material-cms {
      max-width: 100%;
    }

    .add-block-to-material-outside {
      position: static;
      width: auto;
    }
  }

  .l-2 {
    margin-top: 20px;
    font-size: 37px;
    line-height: 44px;
    min-height: 44px;
    &:focus-visible {
      outline: none;
    }
    &:empty:not(:focus)::before {
      content: attr(data-placeholder);
      font-weight: 600;
      font-size: 37px;
      line-height: 44px;
      color: $gray200;
    }
  }

  .s-2 {
    min-height: 34px;
    width: 100%;
    margin-top: 24px;
    &:focus-visible {
      outline: none;
    }
    &:empty:not(:focus)::before {
      content: attr(data-placeholder);
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      color: $gray200;
    }
  }

  .article-link-prepare {
    min-height: 34px;
    width: 100%;
    margin-top: 24px;
    overflow: hidden;
    &:focus-visible {
      outline: none;
    }
    &:hover {
      color: $primary;
    }
    &:empty:not(:focus)::before {
      content: attr(data-placeholder);
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: $gray200;
    }
  }

  mark {
    display: flex;
    align-items: center;
    min-height: 34px;
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    background-color: #ECECF7;

    &:focus-visible {
      outline: none;
    }
    &:empty:not(:focus)::before {
      content: attr(data-placeholder);
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: $gray200;
    }
  }

  .article-video {
    width: 100%;
  }

  .article-img {
    width: 100%;
  }

  blockquote {
    min-height: 32px;
    width: 100%;
    &:focus-visible {
      outline: none;
    }
    &:empty:not(:focus)::before {
      content: attr(data-placeholder);
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: $gray200;
    }
  }
}


.add-material-container {
  position: relative;
  background-color: $white;
  border-radius: 16px;
  margin-bottom: 66px;
  padding: 24px 362px 0 362px;

  @media (max-width: 1920px) {
    padding: 24px 262px 0 262px;
  }

  .material-preparing-footer__inner {
    padding: 0;
  }
}
.material-cms {
  position: relative;
  max-width: 1064px;

  .article-modal__header {
    margin-bottom: 24px;
  }

  .material-preparing__wrapper {
    padding: 0;
  }

  .material-preparing-card__name {
    color: $black;
  }
}

.add-block-to-material {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 36px;


  &.type-to-filter {
    margin-top: 18px;
    span {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $gray500;
    }
  }

  .add-block-to-material-outside {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    width: 52px;
    left: -52px;
    padding-right: 12px;
  }

  .main-loader {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }

  .dropdown-content-list {
    top: 38px;
    left: 32px;
  }

  .outside-click-wrapper {
    height: 20px;
  }

  .dropdown-page {
    height: 100%;
  }
  .dropdown-btn-icon {
    height: 100%;
    border-radius: 4px;
    cursor: pointer;
    .icon {
      color: $gray500;
    }
    &:hover {
      background: $grayPurple;
      .icon {
        color: $gray700;
      }
    }
  }
}
.add-block-to-material-modal {
  position: relative;
  margin: 24px 0;

  .add-to-cms__label {
    padding: 6px 12px;
    background: $gray100;
    border-radius: 6px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $gray300;
  }

  .dropdown-content-list {
    top: 44px;
    left: 20px;
    &.cms-add__modal-dropdown {
      width: 336px;
    }
  }
  .tab-label-list {
    padding: 12px 0 0 16px;
    .tab-label {
      width: max-content;
    }
  }

  .cms-add__modal-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 24px;

    .field {
      margin-bottom: 24px;
    }
  }

  .cms-add__modal-btn {
    display: flex;
    justify-content: center;
  }
}


.article-video {
  iframe {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
  }
}
