.page {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: $grayPurple;

  .ant-space {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &.mobile {
    flex-direction: column;

    .page__content {
      padding: 0 16px 16px 16px;
    }

    .page__container {
      padding: 0;
    }

    .ant-space {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: $white;
      z-index: 2;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    width: 100%;
  }

  &__container {
    width: 100%;
    position: relative;
    padding: 0 84px 36px 84px;

    @media (max-width: 1560px) {
      padding: 0 32px 32px 32px;
    }
  }

  &__right-sidebar {
    display: flex;
    position: relative;

    .right-sidebar {
      margin-left: 84px;
    }
  }

  &__landing {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .landing-logo {
      position: absolute;
      top: 56px;
    }

    .article-item {
      cursor: not-allowed;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: 2;
      }
    }
  }


  &__material {
    background-color: $white;
    border-radius: 16px;
    padding: 30px 16px 16px 16px;
    margin-bottom: 36px;
  }
}

.mobile-tab-bar-content {
  padding: 16px;
}


.feed__page {
  &-wrapper {
    display: flex;
    width: 100%;
    gap: 24px;
  }

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  &-grid-articles {
    display: flex;
    gap: 16px;

    &-col {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
