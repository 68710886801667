.article-opened {
  display: flex;
  gap: 72px;
  justify-content: flex-end;

  @media (max-width: 1240px) {
    gap: 54px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 752px;
    gap: 48px;

    @media (max-width: 1240px) {
      max-width: calc(100% - 100px);
    }

    .button__back {
      padding: 0;
    }

    .article-video {
      margin-bottom: 18px;
    }
  }

  &-content {
    width: 100%;

    img {
      width: auto;
      max-width: 100%;
    }

    .start-course-btn {
      width: 368px;
    }

    .course-actions__footer {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 16px;
      margin-top: 48px;
    }

    .course-participants {
      display: flex;
      align-items: center;
      gap: 12px;

      &__label {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: $gray700;
      }
    }
  }

  &-sidebar {
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex-shrink: 0;
    position: sticky;
    top: 160px;
    right: 0;
    height: max-content;
    margin-right: 72px;

    @media (max-width: 1240px) {
      margin-right: 0;
    }

    .start-course-btn {
      @media (max-width: 1240px) {
        padding: 10px 0;
        width: 36px;

        .btn__text {
          display: none;
        }
      }
    }
  }

  &-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
    border-top: 1px solid $gray100;
    padding-top: 48px;

    &__user {
      display: flex;
      gap: 16px;
      align-items: center;

      .avatar {
        width: 60px;
        height: 60px;

        &.empty {
          font-size: 20px;
        }
      }

      .user-info {
        display: flex;
        flex-direction: column;
        height: max-content;

        .user-info__title {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: $gray500;
        }

        .user-info__name {
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          color: $black;
        }
      }
    }

    &__topics {
      display: flex;
      gap: 12px;
    }

    .article-follow-btn {
      padding: 10px 20px 10px 18px;
      gap: 6px;

      .btn__icon-left {
        width: 16px;
        height: 16px;
      }

      .btn__text {
        font-weight: 400;
      }
    }
  }

  &__related-articles {
    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;
      overflow: hidden;

      .m-5 {
        margin-bottom: 0;
      }
    }

    &-list {
      display: flex;
      gap: 20px;
      overflow-x: auto;
      //width: 100%;

      //scrollbar-width: none;
      //-ms-overflow-style: none;
      //&::-webkit-scrollbar {
      //  width: 0;
      //  height: 0;
      //}
    }
  }

  &-actions {
    display: flex;
    gap: 40px;

    &_col {
      flex-direction: column;
      flex-grow: 1;
      gap: 24px;
    }
  }

  .start-course-btn {
    gap: 6px;

    .icon {
      width: 16px;
      height: 16px;
    }
  }
}


.metrics-list {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  .metrics-list__item {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 6px 12px;
    gap: 10px;
    background: #A39FD7;
    border: 1px solid #7D78C6;
    border-radius: 8px;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FEFEFE;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
