.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $white;
  background-position: center;
  background-size: cover;
  flex-shrink: 0;

  &.empty {
    border: 2px solid $gray100;
    font-weight: 600;
    font-size: 14px;
    color: $black;

  }

  &.xxx {
    width: 120px;
    height: 120px;
    font-size: 40px;
  }

  &.xx {
    width: 100px;
    height: 100px;
    font-size: 30px;
  }

  &.xlg {
    width: 44px;
    height: 44px;
  }

  &.lg {
    width: 36px;
    height: 36px;
  }

  &.md {
    width: 32px;
    height: 32px;
  }
}

.avatar-edit {
  position: relative;
  display: inline-flex;
}

.avatar-edit__btn {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  background-color: $white;
  cursor: pointer;
}
