.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 48px 0 84px 0;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }

  &__wrapper {
    min-width: 496px;
    margin-top: 20px;
    padding: 36px 24px;
    border-radius: 16px;
    background-color: $white;
  }

  &-form {
    .btn {
      margin-top: 16px;
    }
  }

  &__title {
    margin-bottom: 36px;
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: $black;
  }

  &__email {
    color: $primary;
  }

  &-logo {
    display: inline-flex;
    width: 156px;

    &__img {
      width: 100%;
      height: auto;
    }
  }

  &__buttons {
    .btn {
      + .btn {
        margin-top: 16px;
      }

      img {
        width: 20px;
        height: auto;
        margin-left: 12px;
      }
    }
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 16px;
    border: 1px solid $gray100;
    background-color: $white;
    box-shadow: 0 2px 16px rgba(10, 9, 18, 0.06);
    border-radius: 12px;

    + .avaliable-organizations-item {
      margin-top: 16px;
    }

    .btn {
      margin-left: auto;
    }

    &__logo {
      width: 66px;
      height: auto;
    }

    &__inner {
      margin-left: 20px;
    }

    &__title {
      text-align: left;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: $black;
    }

    .people-list {
      margin-top: 8px;
    }
  }

  &-organization {
    display: flex;
    align-items: center;
    padding: 16px;
    border: 1px solid $gray100;
    background-color: $white;
    box-shadow: 0 2px 16px rgba(10, 9, 18, 0.06);
    border-radius: 12px;

    + .login-organization {
      margin-top: 16px;
    }

    .btn {
      margin-left: auto;
      padding: 4px 0;
    }

    &__logo {
      width: 66px;
      height: auto;
    }

    &__inner {
      margin-left: 20px;
    }

    &__title {
      text-align: left;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: $black;
    }

    .people-list {
      margin-top: 8px;
    }
  }
}
