.article-item {
  &.article-item__progress {
    .article-item__content-body {
      min-height: 80px;
    }
    .article-item__content-footer {
      padding-bottom: 4px;
    }

    .progressbar {
      max-width: 100%;
    }

    .progressbar__line-bg {
      background: none;
    }
  }

  &.article-item__progress_row {
    height: 125px;

    .article-item__wrapper {
      flex-direction: row;
      gap: 16px;
    }

    .article-item__content-image {
      width: 222px;
      height: 100%;
    }

    .article-item__content-body {
      justify-content: space-between;
      width: 100%;
    }

    .article-item__content-body__headers {
      padding: 0;
    }

    .article-item__content-body__title {
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: -0.5px;
    }

    .article-item__content-body__description {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .article-item__content-footer {
      width: 100%;
      padding-bottom: 4px;
    }
  }
}
