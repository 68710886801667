.dropdown {
  position: relative;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  &.icon {
    display: flex;
    align-items: center;
    width: max-content;
    height: max-content;
  }

  &-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 12px 8px 12px;
    border: none;
    background: transparent;
    cursor: pointer;
    height: 100%;

    &.active,
    &:hover {
      .icon {
        color: $primary;
      }
    }


    &__name {
      font-size: 16px;
      line-height: 24px;
      color: $black;
      flex-shrink: 0;
    }

    .avatar {
      margin: 0 8px 0 4px;
    }

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  &-search {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    margin: 0 -4px;
    padding: 4px;
    border-bottom: 1px solid $gray200;

    &__label {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      height: 100%;

      .icon {
        width: 16px;
        height: 16px;
        color: $gray500;
        transition: color 0.3s;
      }
    }

    &__input.ant-input {
      height: 100%;
      padding-left: 40px;
      border: none;
      background-color: transparent;
      border-radius: 8px;
      font-size: 16px;
      color: $black;
      transition: background-color 0.3s, border-color 0.3s, color 0.3s;

      &:hover {
        border: none;
      }

      &.ant-input-focused,
      &:focus {
        border: none;
        box-shadow: none;
      }

      &::placeholder {
        color: $gray400;
      }
    }
  }

  &-content {
    position: relative;

    &-list {
      position: absolute;
      z-index: 5;
      padding: 4px;
      width: 248px;
      border: 1px solid $gray200;
      box-shadow: 0 4px 48px rgba(10, 9, 18, 0.08);
      border-radius: 12px;
      background-color: $white;

      .tab + .tab {
        margin-top: 4px;
      }

      &__nav {
        display: flex;
        flex-direction: column;

        .tab__left {
          .icon {
            color: $gray900;
          }
        }
      }
    }

    &-switch {
      margin-top: 4px;

      &__text {
        padding: 12px 12px 4px 12px;
        font-weight: 600;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $gray500;
      }
    }
  }

  &.dropdown-account,
  &.dropdown-notification {
    .dropdown-content-list {
      top: 52px;
    }
  }

  &.notification {
    .dropdown-content-list {
      width: 448px;
      max-height: 364px;
      overflow: auto;
      z-index: 5;
    }
  }


  &.dropdown-page {

    .dropdown-btn {
      align-items: center;
    }

    &:hover,
    &.active {
      .dropdown-btn__name {
        color: $primary;
      }
    }

    .dropdown-content-list {
      top: 32px;
    }
  }

  &.right {
    .dropdown-content-list {
      right: 16px;
    }
  }

  &.left {
    .dropdown-content-list {
      left: 16px;
    }
  }

  &.custom {
    .dropdown-content-list {
      right: 16px;
      top: 6px;
    }
  }
}

.ant-dropdown {
  position: absolute;
  top: 74px !important;
  z-index: 13;
  padding: 4px !important;
  border: 1px solid $gray200;
  box-shadow: 0 4px 48px rgba(10, 9, 18, 0.08);
  border-radius: 12px;
  background-color: $white;
}

.ant-dropdown-trigger {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  height: 100%;

  &:hover,
  &.ant-dropdown-open {
    .icon {
      color: $primary;
    }
  }

  &.ant-dropdown-open {
    .icon-arrow {
      transform: rotate(180deg);
    }
  }

  .avatar {
    margin: 0 8px 0 4px;
  }

  .icon {
    width: 16px;
    height: 16px;
    color: $black;
  }
}
