.page-template__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;

  .button__back {
    padding: 0;
  }

  .page-template__content {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .page-template__filter {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $gray200;
    gap: 8px;

    &_not-single {
      .outside-click-wrapper {
        margin-left: auto;
      }
    }

    &_with-tab {
      display: flex;
      width: 100%;
      border-bottom: 1px solid $gray200;

      .page-template__filter {
        border-bottom: none;
        justify-content: flex-end;
      }
    }

    &.sort-and-view-btn {
      display: flex;
      justify-content: space-between;
      padding-bottom: 4px;
    }
  }

  .page-template__articles {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    column-gap: 16px;
    row-gap: 32px;
  }
}


.page-filter-view-btn {
  display: flex;
  gap: 8px;
}

.page-template__grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, max-content);
  row-gap: 32px;
  width: 100%;

  .page-template__filter_with-tab {
    grid-area: 1 / 1 / 2 / 2;
  }

  .page-template__filter {
    grid-area: 1 / 2 / 2 / 3;
    justify-content: flex-end
  }

  .page-template__content {
    grid-area: 2 / 1 / 3 / 3;
  }

}

